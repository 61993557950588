import { useState, useEffect, useContext } from 'react';
import { googleLogout, GoogleLogin } from '@react-oauth/google';
import { toast } from 'react-hot-toast';
import { LOGIN } from '../../constants/AppConstants';
import './styles.scss';
// import googleLogo from "../../assets/googlelogo.svg";
import UserContext from '../../context/userContext';
import loginUser from '../../services/loginService';

export const logOutUser = (navigate, setUserLoggedIn) => {
  googleLogout();
  setUserLoggedIn(false);
  localStorage.removeItem('accessToken');
  toast.error('Account Logged Out', { autoClose: 1000 });
  navigate('/');
};

export function Login() {
  const [setError] = useState('');
  const errorMessage = (error) => {
    setError(error);
  };
  const { setUserLoggedIn } = useContext(UserContext);
  const [user, setUser] = useState('');

  // const loginViaGoogle = useGoogleLogin({
  //   onSuccess: (codeResponse) => authenticateUser(codeResponse),
  //   onError: (error) => console.log(error),
  // });
  const authenticateUser = async (token) => {
    // Note: Hit Backend API here
    const userData = {
      credentials: token.credential,
      provider: 'Google',
    };
    loginUser(userData)
      .then((res) => {
        setUserLoggedIn(true);
        localStorage.setItem('accessToken', JSON.stringify(res[1].accesstoken));
        setUser(res[1].accesstoken);
        toast.success('Welcome');
      })
      .catch(() => {
        toast.error('Credentails Revoked');
      });
  };

  useEffect(() => {
    if (user) {
      authenticateUser(user);
    }
  });

  return (
    <div className="container-fluid login-container">
      <div className="login-card">
        <div className="description-box">
          <h2>{LOGIN.header}</h2>
          <br />
          <p>{LOGIN.description}</p>
        </div>
        <div className="login-box">
          <img
            src="https://uploads-ssl.webflow.com/61ffed246e785f28c1a44633/62026bbe9515a02ec226fcfe_Group%201917%20(1).svg"
            alt="Fission labs logo"
            className="login-fission-logo"
          />
          <div className="login-form">
            <p className="login-info text-center">{LOGIN.title}</p>
            {/* <button
                className="login-button text-center mt-5"
                onClick={() => loginViaGoogle()}
              >
                <img
                  className="google-logo"
                  src={googleLogo}
                  alt="google logo"
                />
                <span className="login-text">{LOGIN.text}</span>
              </button> */}{' '}
            <GoogleLogin onSuccess={authenticateUser} onError={errorMessage} />
          </div>
        </div>
      </div>
    </div>
  );
}
