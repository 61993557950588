import moment from 'moment';
import { GoDotFill } from 'react-icons/go';
import ProgressBar from 'react-bootstrap/ProgressBar';

export const updateField = (name, key, value) => {
  const copy = { ...{ key } };
  const error = value.toString().length !== 0;
  const errorMsg = value.toString().length !== 0 ? '' : `Please select ${name}`;
  return { ...copy, value, error, errorMsg };
};

export const nameRenderer = (params) => (
  <span>{params?.value?.name ? params?.value?.name : 'NA'}</span>
);

export const clientManagerRenderer = (params) => <span>{params?.value?.clientManagerName}</span>;

export const roleRenderer = (params) => {
  if (params?.value) {
    return <span>{params?.value?.map((item) => item?.role)}</span>;
  }
  return '';
};

export const dateRenderer = (params) => {
  if (params?.value) {
    return <span>{moment(params?.value).format('DD-MM-YYYY')}</span>;
  }
  return <span>NA</span>;
};

export const stateRenderer = (params) => {
  if (params?.value === true) {
    return (
      <p>
        <GoDotFill className="text-success fs-5" />
        Active
      </p>
    );
  }
  if (params?.value === false) {
    return (
      <p>
        <GoDotFill className="text-danger fs-5" />
        In Active
      </p>
    );
  }
  return '';
};

export const yesOrNoStateRenderer = (params) => {
  if (params?.value === true) {
    return (
      <p>
        <GoDotFill className="text-success fs-4" />
        Yes
      </p>
    );
  }
  if (params?.value === false) {
    return (
      <p>
        <GoDotFill className="text-danger fs-4" />
        No
      </p>
    );
  }
  return '';
};

export const progressBarRenderer = (params) => {
  if (params?.value) {
    return (
      <div className="d-flex align-items-center">
        <ProgressBar animated now={params?.value} className="me-2 w-75" />
        <span>{params?.value}%</span>
      </div>
    );
  }
  return '';
};

export const yesOrNoRenderer = (params) => {
  if (params?.value === true) {
    return <strong className="text-success">Yes</strong>;
  }
  if (params?.value === false) {
    return <strong className="text-danger">No</strong>;
  }
  return '';
};

export const removeClassNames = () => {
  const projects = document.querySelectorAll('.projects-tab');
  const clients = document.querySelectorAll('.clients-tab');
  const employees = document.querySelectorAll('.employees-tab');
  [...projects].map((item) => item.classList.remove('active-sidebar-tab'));
  [...clients].map((item) => item.classList.remove('active-sidebar-tab'));
  [...employees].map((item) => item.classList.remove('active-sidebar-tab'));
};

const addActiveTabClassname = (elementName) => {
  const element = document.querySelectorAll(elementName);
  [...element].map((item) => item.classList.add('active-sidebar-tab'));
};

export const getActiveTabByRouteName = (pathName) => {
  if (pathName === '/') {
    removeClassNames();
  }
  if (pathName === '/clients') {
    removeClassNames();
  }
  if (pathName === '/projects') {
    removeClassNames();
  }
  if (pathName === '/addEmployee') {
    addActiveTabClassname('.employees-tab');
  }
  if (pathName.includes('/editEmployee')) {
    addActiveTabClassname('.employees-tab');
  }
  if (pathName === '/addClient') {
    addActiveTabClassname('.clients-tab');
  }
  if (pathName.includes('/editClient')) {
    addActiveTabClassname('.clients-tab');
  }
  if (pathName === '/addProject') {
    addActiveTabClassname('.projects-tab');
  }
  if (pathName.includes('/editProject')) {
    addActiveTabClassname('.projects-tab');
  }
  return '';
};
