import { NavLink } from 'react-router-dom';

/* eslint-disable react/prop-types */
function BreadCrumb({ data }) {
  return (
    <div className="ms-1 mb-2">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb m-0">
          {/* <li className="breadcrumb-item" key="home">
            <NavLink className="text-decoration-none" to="/">
              Home
            </NavLink>
          </li> */}
          {data?.map((item) => (
            <li className="breadcrumb-item" key={item.id}>
              {item?.isLink ? (
                <NavLink className="text-decoration-none" to={item?.route}>
                  {item?.name}
                </NavLink>
              ) : (
                item?.name
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
}

export default BreadCrumb;
