import { useState } from 'react';
import { formatQuery, QueryBuilder } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import useEmployeeColumns from '../../pages/employees/EmployeeUtilsHook';

function QueryBuilderWrapper() {
  const fields = useEmployeeColumns();
  const [query, setQuery] = useState({
    combinator: 'and',
    rules: [
      { field: 'designation', operator: 'contains', value: 'lead' },
      { field: 'reportingManager', operator: 'in', value: 'Sara,Sanjeev' },
      { field: 'primaryskill', operator: 'contains', value: 'Java' },
    ],
  });

  return (
    <>
      <QueryBuilder fields={fields} query={query} onQueryChange={(q) => setQuery(q)} />
      <h4>
        SQL as result of <code>formatQuery(query, &lsquo;sql&rsquo;)</code>:
      </h4>
      <pre>{formatQuery(query, 'sql')}</pre>
    </>
  );
}

export default QueryBuilderWrapper;
