/* eslint-disable react-hooks/exhaustive-deps */
import './styles.scss';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Modal } from 'react-bootstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import Card from '../../common-components/card/Card';
import useProjectAllocationColumns from './ProjectAllocationUtilsHook';
import BreadCrumb from '../../common-components/bread-crumb/BreadCrumb';
import {
  arrayOfGenders,
  arrayOfTypes,
  arrayOfStatus,
  arrayOfLocations,
  commonValues,
  employeeForm,
  BUTTONS,
  projectAllocationForm,
} from '../../constants/AppConstants';
import ProjectAllocationForm from './ProjectAllocationForm';
import DataTable from '../../common-components/data-table/DataTable';
import {
  editEmployee,
  createEmployee,
  getAllSkills,
  getAllPractices,
  getAllDesignations,
  getAllDepartments,
  getAllRoles,
  getAllReportingManagers,
  getEmployeeById,
  allocateProjectToEmployee,
  editAllocateProject,
} from '../../services/employeesService';
import {
  deleteProjectAllocation,
  getProjectAllocationById,
  getAllProjectAllocationsByEmpId,
} from '../../services/projectAllocationService';
import ConfirmationModal from '../../common-components/confirmation-modal/ConfirmationModal';

function EmployeeForm() {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      active: 'yes',
    },
  });
  const location = useLocation();
  const employeeId = location.state;
  const navigate = useNavigate();

  const [selectedEmployee, setSelectedEmployee] = useState();
  const [state, setState] = useState([]);
  const [selectedProjectAllocation, setSelectedProjectAllocation] = useState({});
  const [projectAllocationData, setProjectAllocationData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [showPopup, setshowPopup] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isProjectAllocationEdit, setIsProjectAllocationEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [skillsList, setSkillsList] = useState([]);
  const [managersList, setManagersList] = useState([]);
  const [departmentsList, setDepartmentsList] = useState([]);
  const [practicesList, setPracticesList] = useState([]);
  const [designationsList, setDesignationsList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [allocateProjDetails, setAllocateProjDetails] = useState('');

  const getProjectAllocations = () => {
    getAllProjectAllocationsByEmpId(employeeId).then((res) => {
      setProjectAllocationData(res || []);
    });
  };

  useEffect(() => {
    getAllSkills().then((res) => {
      setSkillsList(res);
    });
    getAllPractices().then((res) => {
      setPracticesList(res);
    });
    getAllDesignations().then((res) => {
      setDesignationsList(res);
    });
    getAllDepartments().then((res) => {
      setDepartmentsList(res);
    });
    getAllRoles().then((res) => {
      setRolesList(res);
    });
    getAllReportingManagers().then((res) => {
      setManagersList(res);
    });
    if (location.state) {
      setIsEdit(true);
      getEmployeeById(employeeId).then((res) => {
        setSelectedEmployee(res);
      });
    }
  }, []);

  const handleProjectAllocationStateChange = (newState) => {
    setAllocateProjDetails(newState);
    if (isProjectAllocationEdit) {
      editAllocateProject({ ...newState, employeeId: selectedEmployee.id }).then((res) => {
        if (res) {
          toast.success(projectAllocationForm.messages.update);
          getProjectAllocations();
        } else {
          toast.error(res?.message);
        }
      });
    }
    if (isEdit && !isProjectAllocationEdit) {
      allocateProjectToEmployee({ ...newState, employeeId: selectedEmployee.id }).then((res) => {
        if (res) {
          toast.success(projectAllocationForm.messages.create);
          getProjectAllocations();
        } else {
          toast.error(res?.message);
        }
      });
    }
  };

  useEffect(() => {
    // Edit Employee
    if (selectedEmployee && location.pathname !== '/addEmployee') {
      setState([
        { id: 1, name: 'Employees', route: '/', isLink: true },
        { id: 2, name: 'Edit Employee', route: '', isLink: false },
      ]);
      const name = managersList?.find((m) => m.id === selectedEmployee?.reportingManager?.id);
      const fields = {
        firstName: selectedEmployee?.firstName,
        lastName: selectedEmployee?.lastName,
        email: selectedEmployee?.email,
        orgEmpId: selectedEmployee?.orgEmpId,
        designation: selectedEmployee?.designation,
        nickname: selectedEmployee?.nickname,
        role: selectedEmployee?.roles[0]?.role,
        gender: selectedEmployee?.gender,
        type: selectedEmployee?.type,
        department: selectedEmployee?.department?.name,
        joiningDate: selectedEmployee?.fissionStartDate
          ? selectedEmployee?.fissionStartDate.slice(0, 10)
          : null,
        experienceDate: selectedEmployee?.expStartDate
          ? selectedEmployee?.expStartDate.slice(0, 10)
          : null,
        practice: selectedEmployee?.practice,
        status: selectedEmployee?.status,
        reportingManager: name ? name.name : '',
        location: selectedEmployee?.location,
        primarySkills: selectedEmployee?.skills?.filter((skill) => skill.isPrimary === true) || [],
        otherSkills: selectedEmployee?.skills?.filter((skill) => skill.isPrimary === false) || [],
        contactNumber: selectedEmployee?.contactNumber,
        active: selectedEmployee?.active === true ? 'yes' : 'no',
        billable: selectedEmployee?.billable === true ? 'yes' : 'no',
        notes: selectedEmployee?.notes,
      };

      reset(fields);
    } else {
      // setIsEdit(false);
      setState([
        { id: 1, name: 'Employees', route: '/', isLink: true },
        { id: 2, name: 'Add Employee', route: '', isLink: false },
      ]);
    }
  }, [
    selectedEmployee,
    reset,
    location,
    isEdit,
    skillsList,
    practicesList,
    rolesList,
    designationsList,
    departmentsList,
  ]);

  useEffect(() => {
    if (isEdit) {
      getProjectAllocations();
    }
  }, [isEdit]);

  const onCreateProjectAllocation = (data) => {
    allocateProjectToEmployee({ ...allocateProjDetails, employeeId: data.id }).then((res) => {
      if (res) {
        toast.success(projectAllocationForm.messages.create);
        navigate('/');
      } else {
        toast.error(res?.message);
      }
    });
  };

  const onSubmit = (data) => {
    const primarySkillsList = data.primarySkills.map(({ id, name }) => ({
      id,
      name,
      isPrimary: true,
    }));
    const otherSkillsList = data.otherSkills.map(({ id, name }) => ({
      id,
      name,
      isPrimary: false,
    }));
    const { id, name } = rolesList.find((r) => r.displayName === data.role);
    const employeeReqObj = {
      firstName: data.firstName,
      lastName: data.lastName,
      orgEmpId: data.orgEmpId,
      email: data.email,
      type: data.type,
      designation: data.designation,
      practice: data.practice,
      expStartDate: data.experienceDate ? new Date(data.experienceDate).toISOString() : null,
      fissionStartDate: data.joiningDate ? new Date(data.joiningDate).toISOString() : null,
      contactNumber: data.contactNumber,
      status: data.status,
      department: departmentsList.find((department) => department.name === data.department),
      gender: data.gender,
      location: data.location,
      nickname: data.nickname,
      active: data.active === 'yes',
      billable: data.billable === 'yes',
      notes: data.notes ? data.notes : '',
      roles: [{ id, role: name }],
      reportingManager: managersList.find((manager) => manager.name === data.reportingManager) || {
        id: 'null',
        name: 'null',
      },
      skills: primarySkillsList.concat(otherSkillsList),
      createdBy: 'admin',
      createdTime: '2023-05-06T08:00:00',
      modifiedBy: 'HR',
      modifiedTime: '2023-05-06T08:00:00',
    };
    if (isEdit) {
      editEmployee(employeeReqObj, selectedEmployee?.id).then((res) => {
        if (res) {
          toast.success(employeeForm.messages.update);
          // if(allocateProjDetails && !isProjectAllocationEdit) { onCreateProjectAllocation(res) };
          navigate('/');
        } else {
          toast.error(res?.message);
        }
      });
    } else {
      createEmployee(employeeReqObj).then((res) => {
        if (res) {
          toast.success(employeeForm.messages.create);
          if (allocateProjDetails && !isProjectAllocationEdit) {
            onCreateProjectAllocation(res);
          }
          navigate('/');
        } else {
          toast.error(res?.message);
        }
      });
    }
  };

  const onEdit = (data) => {
    setIsProjectAllocationEdit(true);
    getProjectAllocationById(data.id).then((res) => {
      setSelectedProjectAllocation(res);
    });
    setModal(true);
  };

  const handleOnClick = () => {
    setSelectedProjectAllocation({});
    // setIsEdit(false);
    setModal(true);
    setIsProjectAllocationEdit(false);
  };

  const onModalClosePopup = () => {
    setModal(false);
    setIsProjectAllocationEdit(false);
  };

  const handleCancelEvent = () => {
    navigate('/');
  };

  const onDelete = (data) => {
    setSelectedData(data);
    setshowPopup(true);
  };

  const onDeleteConfirm = (data) => {
    deleteProjectAllocation(data?.id).then((res) => {
      if (res.status === commonValues.successful) {
        toast.success(res.message);
        getProjectAllocations();
      } else {
        toast.error(res.message);
      }
    });
  };

  const onConfirm = () => {
    onDeleteConfirm(selectedData);
    setshowPopup(false);
  };

  const onClosePopup = () => {
    setshowPopup(false);
    setSelectedData('');
  };

  const handleOnClickAddProject = () => {
    navigate('/addProject');
  };

  const projectAllocationTableColumns = [
    ...useProjectAllocationColumns(),
    {
      field: 'action',
      headerName: 'Action',
      headerClass: 'my-header-class',
      cellStyle: { textAlign: 'center' },
      cellRenderer: (params) => (
        <>
          <FaEdit onClick={() => onEdit(params?.data)} className="edit-icon" />
          <FaTrash onClick={() => onDelete(params?.data)} className="delete-icon ms-1" />
        </>
      ),
    },
  ];

  return (
    <div className="px-1 pb-4">
      <BreadCrumb data={state} />
      <button type="button" className="btn submit-btn float-end mt-1" onClick={handleOnClick}>
        {BUTTONS.allocateProject}
      </button>
      <h4 className="mt-4">{isEdit ? `${employeeForm.title2}` : `${employeeForm.title1}`}</h4>
      <Card className="px-4 py-3 mt-3">
        {/* eslint-disable react/jsx-props-no-spreading */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="firstName">{employeeForm.labels.firstName}</label>{' '}
              <span className="error">*</span>
              <input
                id="firstName"
                type="text"
                className="form-control"
                placeholder="First Name"
                {...register('firstName', { required: true, maxLength: 20 })}
              />
              {errors.firstName && (
                <p className="error mb-0">{employeeForm.validations.firstName}</p>
              )}
            </div>

            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="lastName">{employeeForm.labels.lastName}</label>{' '}
              <span className="error">*</span>
              <input
                id="lastName"
                type="text"
                className="form-control"
                placeholder="Last Name"
                {...register('lastName', { required: true, maxLength: 20 })}
              />
              {errors.lastName && <p className="error mb-0">{employeeForm.validations.lastName}</p>}
            </div>

            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="email">{employeeForm.labels.email}</label>{' '}
              <span className="error">*</span>
              <input
                id="email"
                type="email"
                disabled={isEdit}
                className="form-control"
                placeholder="Email"
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@fissionlabs\.com$/i,
                    message: 'Invalid email address',
                  },
                })}
              />
              {errors.email && <p className="error mb-0">{errors.email.message}</p>}
            </div>

            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="orgEmpId">{employeeForm.labels.fissionId}</label>{' '}
              <span className="error">*</span>
              <input
                id="orgEmpId"
                type="text"
                disabled={isEdit}
                className="form-control"
                placeholder="Fission ID"
                {...register('orgEmpId', {
                  required: 'Fission Id is required',
                  pattern: {
                    value: /^FL\d{4}$/,
                    message: 'Invalid pattern',
                  },
                })}
              />
              {errors.orgEmpId && <p className="error mb-0">{errors.orgEmpId.message}</p>}
            </div>

            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="role">{employeeForm.labels.role}</label>{' '}
              <span className="error">*</span>
              <select id="role" {...register('role', { required: true })} className="form-select">
                <option value="">{commonValues.select}</option>
                {rolesList?.map((roles) => (
                  <option key={roles.id} value={roles.displayName}>
                    {roles.displayName}
                  </option>
                ))}
              </select>
              {errors.role && <p className="error mb-0">{employeeForm.validations.role}</p>}
            </div>

            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="designation">{employeeForm.labels.designation}</label>{' '}
              <span className="error">*</span>
              <select
                id="designation"
                {...register('designation', { required: true })}
                className="form-select"
              >
                <option value="">{commonValues.select}</option>
                {designationsList?.map((designation) => (
                  <option key={designation.id} value={designation.name}>
                    {designation.name}
                  </option>
                ))}
              </select>
              {errors.designation && (
                <p className="error mb-0">{employeeForm.validations.designation}</p>
              )}
            </div>

            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="nickname">{employeeForm.labels.nickname}</label>
              <input
                type="text"
                id="nickname"
                className="form-control"
                placeholder="Nick Name"
                {...register('nickname')}
              />
            </div>

            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="gender">{employeeForm.labels.gender}</label>{' '}
              <span className="error">*</span>
              <select
                id="gender"
                disabled={isEdit}
                {...register('gender', { required: true })}
                className="form-select"
              >
                <option value="">{commonValues.select}</option>
                {arrayOfGenders?.map((gender) => (
                  <option key={gender} value={gender}>
                    {gender}
                  </option>
                ))}
              </select>
              {errors.gender && <p className="error mb-0">{employeeForm.validations.gender}</p>}
            </div>

            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="type">{employeeForm.labels.type}</label>{' '}
              <span className="error">*</span>
              <select id="type" {...register('type', { required: true })} className="form-select">
                <option value="">{commonValues.select}</option>
                {arrayOfTypes?.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
              {errors.type && <p className="error mb-0">{employeeForm.validations.type}</p>}
            </div>

            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="department">{employeeForm.labels.department}</label>{' '}
              <span className="error">*</span>
              <select
                id="department"
                {...register('department', { required: true })}
                className="form-select"
              >
                <option value="">{commonValues.select}</option>
                {departmentsList?.map((department) => (
                  <option key={department.id} value={department.name}>
                    {department.name}
                  </option>
                ))}
              </select>
              {errors.department && (
                <p className="error mb-0">{employeeForm.validations.department}</p>
              )}
            </div>

            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="joiningDate">{employeeForm.labels.joiningDate}</label>{' '}
              <span className="error">*</span>
              <input
                type="date"
                id="joiningDate"
                disabled={isEdit}
                className="form-control session-date"
                {...register('joiningDate', {
                  required: true,
                })}
              />
              {errors.joiningDate && (
                <p className="error mb-0">{employeeForm.validations.joiningDate}</p>
              )}
            </div>

            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="experienceDate">{employeeForm.labels.experienceDate}</label>{' '}
              <span className="error">*</span>
              <input
                type="date"
                id="experienceDate"
                className="form-control session-date"
                placeholder="experienceDate"
                {...register('experienceDate', {
                  required: true,
                })}
              />
              {errors.experienceDate && (
                <p className="error mb-0">{employeeForm.validations.experienceDate}</p>
              )}
            </div>

            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="practice">{employeeForm.labels.practice}</label>{' '}
              <span className="error">*</span>
              <select
                id="practice"
                {...register('practice', { required: true })}
                className="form-select"
              >
                <option value="">{commonValues.select}</option>
                {practicesList?.map((practice) => (
                  <option key={practice.orgId} value={practice.name}>
                    {practice.name ? practice.name : '--'}
                  </option>
                ))}
              </select>
              {errors.practice && <p className="error mb-0">{employeeForm.validations.practice}</p>}
            </div>

            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="status">{employeeForm.labels.status}</label>{' '}
              <span className="error">*</span>
              <select
                id="status"
                {...register('status', { required: true })}
                className="form-select"
              >
                <option value="">{commonValues.select}</option>
                {arrayOfStatus?.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </select>
              {errors.status && <p className="error mb-0">{employeeForm.validations.status}</p>}
            </div>

            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="reportingManager">{employeeForm.labels.reportingManager}</label>
              {/* <span className="error">*</span> */}
              <select
                id="reportingManager"
                {...register('reportingManager')}
                className="form-select"
              >
                <option value="">{commonValues.select}</option>
                {managersList?.map((manager) => (
                  <option key={manager.id} value={manager.name}>
                    {manager.name}
                  </option>
                ))}
              </select>
              {/* {errors.reportingManager && (
                <p className="error mb-0">{employeeForm.validations.reportingManager}</p>
              )} */}
            </div>

            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="location">{employeeForm.labels.location}</label>{' '}
              <span className="error">*</span>
              <select
                id="location"
                {...register('location', { required: true })}
                className="form-select"
              >
                <option value="">{commonValues.select}</option>
                {arrayOfLocations?.map((locations) => (
                  <option key={locations} value={locations}>
                    {locations}
                  </option>
                ))}
              </select>
              {errors.location && <p className="error mb-0">{employeeForm.validations.location}</p>}
            </div>

            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="primarySkills">{employeeForm.labels.primarySkill}</label>{' '}
              <span className="error">*</span>
              <Controller
                name="primarySkills"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Typeahead
                    multiple
                    id="primarySkills"
                    labelKey="name"
                    onChange={(selected) => field.onChange(selected)}
                    options={skillsList}
                    placeholder="Select skills"
                    selected={field.value}
                  />
                )}
              />
              {errors.primarySkills && (
                <p className="error mb-0">{employeeForm.validations.primarySkill}</p>
              )}
            </div>

            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="otherSkills">{employeeForm.labels.otherSkills}</label>{' '}
              <span className="error">*</span>
              <Controller
                name="otherSkills"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Typeahead
                    multiple
                    id="otherSkills"
                    labelKey="name"
                    onChange={(selected) => field.onChange(selected)}
                    options={skillsList}
                    placeholder="Select skills"
                    selected={field.value}
                  />
                )}
              />
              {errors.otherSkills && (
                <p className="error mb-0">{employeeForm.validations.otherSkills}</p>
              )}
            </div>

            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="contactNumber">{employeeForm.labels.contactNumber}</label>{' '}
              <span className="error">*</span>
              <input
                type="number"
                id="contactNumber"
                className="form-control"
                placeholder="Contact Number"
                {...register('contactNumber', {
                  required: 'Contact Number is required',
                  pattern: {
                    value: /^\d{10}$/,
                    message: 'Invalid Contact Number',
                  },
                })}
              />
              {errors.contactNumber && <p className="error mb-0">{errors.contactNumber.message}</p>}
            </div>

            <div className="col d-flex align-items-center gap-3 mt-1">
              <div className="border rounded col p-2 text-center">
                <label htmlFor="active" className="me-5">
                  {employeeForm.labels.active}
                </label>
                <label htmlFor="active">
                  <input
                    type="radio"
                    value="yes"
                    {...register('active')}
                    disabled={selectedEmployee?.active === false && isEdit}
                    className="me-1"
                  />
                  {commonValues.yes}
                </label>
                <label htmlFor="active" className="ms-4">
                  <input
                    type="radio"
                    value="no"
                    disabled={!isEdit}
                    {...register('active', { required: true })}
                    className="me-1"
                  />
                  {commonValues.no}
                </label>
                {errors.active && <p className="error mb-0">{employeeForm.validations.active}</p>}
              </div>
              <div className="border rounded col p-2 text-center">
                <label htmlFor="billable" className="me-5">
                  {employeeForm.labels.billable}
                </label>
                <label htmlFor="billable">
                  <input
                    type="radio"
                    value="yes"
                    {...register('billable', { required: true })}
                    className="me-1"
                  />
                  {commonValues.yes}
                </label>
                <label htmlFor="billable" className="ms-4">
                  <input
                    type="radio"
                    value="no"
                    {...register('billable', { required: true })}
                    className="me-1"
                  />
                  {commonValues.no}
                </label>
                {errors.billable && (
                  <p className="error mb-0">{employeeForm.validations.billable}</p>
                )}
              </div>
            </div>

            <div className="mb-2">
              <label htmlFor="notes">{employeeForm.labels.notes}</label>
              <textarea
                id="notes"
                {...register('notes', { maxLength: 750 })}
                className="form-control"
                placeholder="Write a Note"
              />
              {errors.notes && <p className="error mb-0">{employeeForm.validations.notes}</p>}
            </div>

            {isEdit && projectAllocationData ? (
              <>
                <DataTable
                  height={250}
                  columns={projectAllocationTableColumns}
                  rows={isEdit ? projectAllocationData : []}
                  tableTitle={projectAllocationForm.tableTitle}
                  pagination
                />
                <ConfirmationModal
                  showPopup={showPopup}
                  header="Delete"
                  body="Are you sure you want to Delete the Project allocated?"
                  cancel="Cancel"
                  confirm="Delete"
                  onConfirm={onConfirm}
                  onClosePopup={onClosePopup}
                />
              </>
            ) : (
              ''
            )}
          </div>

          <div className="d-flex justify-content-center pt-4">
            <button type="submit" className="btn submit-btn px-4">
              {BUTTONS.submit}
            </button>
            <button type="button" className="btn cancel-btn ms-4 px-4" onClick={handleCancelEvent}>
              {BUTTONS.cancel}
            </button>
          </div>
        </form>
        {/* eslint-enable react/jsx-props-no-spreading */}
      </Card>

      <Modal show={modal} onHide={onModalClosePopup} centered size="xl">
        <Modal.Header closeButton>
          <div className="row w-100">
            <div className="col">
              <Modal.Title className="mx-3">Project Allocation</Modal.Title>
            </div>
            <div className="col text-end me-3">
              <button
                type="button"
                className="btn submit-btn mt-1"
                onClick={handleOnClickAddProject}
              >
                {BUTTONS.addProject}
              </button>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <ProjectAllocationForm
            onModalClosePopup={onModalClosePopup}
            setModal={setModal}
            onProjectAllocation={handleProjectAllocationStateChange}
            selectedProjectAllocation={selectedProjectAllocation}
            isEdit={isEdit}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default EmployeeForm;
