import { useContext, useEffect, useState } from 'react';
import { googleLogout } from '@react-oauth/google';
import { useNavigate } from 'react-router';
import { ErrorBoundary } from 'react-error-boundary';
import Header from '../common-components/header/Header';
import Sidebar from '../common-components/sidebar/Sidebar';
import UserContext from '../context/userContext';
import WrappedWithErrorBoundary from '../common-components/Errors/WrappedWithErrorBoundary';
// import PropTypes from "prop-types";

// eslint-disable-next-line react/prop-types
function AuthenticatedLayout({ children }) {
  const { setUserLoggedIn, userLoggedIn } = useContext(UserContext);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const childrenLayoutClassname = `children layout-children ${isSideBarOpen && 'shirnk-layout'}`;
  const navigate = useNavigate();

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem('accessToken'))) {
      setUserLoggedIn(false);
      googleLogout();
      navigate('/');
    }
  }, [setUserLoggedIn, userLoggedIn, navigate]);

  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(window.innerWidth);
      if (window.innerWidth > 800) {
        setIsSideBarOpen(false);
      }
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenWidth]);

  return (
    <ErrorBoundary FallbackComponent={WrappedWithErrorBoundary}>
      <Header
        isSideBarOpen={isSideBarOpen}
        setIsSideBarOpen={setIsSideBarOpen}
        screenWidth={screenWidth}
      />
      <div className="body-container">
        <Sidebar
          isSideBarOpen={isSideBarOpen}
          screenWidth={screenWidth}
          setIsSideBarOpen={setIsSideBarOpen}
        />
        <div className={childrenLayoutClassname}>{children}</div>
      </div>{' '}
    </ErrorBoundary>
  );
}

// AuthenticatedLayout.propTypes = {
//   children: PropTypes.node.isRequired,
// };

export default AuthenticatedLayout;
