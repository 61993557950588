// export const loggedInUserInfoState
import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
  userInfoRequest: [],
  userInfoSuccess: [],
  userInfoFailure: [],
});

export { Types, Creators };
const INITIAL_STATE = {
  userInfoRequest: false,
  userInfoSuccess: [],
  userInfoFailure: false,
};

const onUserInfoRequest = (state = INITIAL_STATE) => ({
  ...state,
  userInfoRequest: true,
  userInfoFailure: false,
});

const onUserInfoSuccess = (data, state = INITIAL_STATE) => ({
  ...state,
  userInfoRequest: false,
  userInfoSuccess: data.payload,
  userInfoFailure: false,
});

const onUserInfoFailure = (state = INITIAL_STATE) => ({
  ...state,
  userInfoRequest: false,
  userInfoSuccess: [],
  userInfoFailure: true,
});

const handlers = {
  [Types.USER_INFO_REQUEST]: onUserInfoRequest,
  [Types.USER_INFO_SUCCESS]: onUserInfoSuccess,
  [Types.USER_INFO_FAILURE]: onUserInfoFailure,
};

export default createReducer(INITIAL_STATE, handlers);
