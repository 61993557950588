/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import DataTable from '../../common-components/data-table/DataTable';
import useClientColumns from './ClientUtilsHook';
import { commonValues } from '../../constants/AppConstants';
import { getAllClients, deleteClient } from '../../services/clientService';
import ConfirmationModal from '../../common-components/confirmation-modal/ConfirmationModal';

function ClientsTable() {
  const [clients, setClients] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const navigate = useNavigate();
  const addClientHandler = () => {
    navigate('/addClient');
  };

  const getClients = () =>
    getAllClients().then((response) => {
      setClients(response || []);
    });

  useEffect(() => {
    getClients();
  }, []);

  const handleDeleteClick = (data) => {
    setShowPopup(true);
    setSelectedData(data);
  };

  const onDeleteConfirm = (data) => {
    deleteClient(data?.id).then((res) => {
      if (res.status === commonValues.successful) {
        toast.success(res.message);
        getClients();
      } else {
        toast.error(res.message);
      }
    });
  };

  const onConfirm = () => {
    onDeleteConfirm(selectedData);
    setShowPopup(false);
  };

  const dummyColums = useClientColumns(handleDeleteClick);

  const onClosePopup = () => {
    setShowPopup(false);
    setSelectedData('');
  };

  return (
    <>
      <DataTable
        rows={clients}
        columns={dummyColums}
        addButton
        height={500}
        tableTitle="Clients"
        pagination
        enableGlobalSearch
        addButtonListener={addClientHandler}
      />
      <ConfirmationModal
        showPopup={showPopup}
        header="Delete"
        body="Are you sure you want to Delete the Client?"
        cancel="Cancel"
        confirm="Delete"
        onConfirm={onConfirm}
        onClosePopup={onClosePopup}
      />
    </>
  );
}

export default ClientsTable;
