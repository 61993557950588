import axios from 'axios';
import { toast } from 'react-hot-toast';
import getHeaders from './Utilities';

export const baseURL = 'https://people-hrms.fissionlabs.com'; // api end point

class ApiException {
  details;

  message;

  statusCode;

  timestamp;

  constructor(details = '', message = '', statusCode = '', timestamp = '') {
    this.details = details;
    this.message = message;
    this.statusCode = statusCode;
    this.timestamp = timestamp;
  }
}

/**
 * @param {string} path - API endpoint
 * @param {string} method - Request method type
 * @param {Object} hederType - header
 * @param {string} data - request payload
 * @param {string} extraParams - additional Parameters in request
 * @param {string} queryParams - query parameters
 */

export const makeAPICall = async (path, method, headerType, data, extraParams, queryParams) => {
  const requestConfiguration = {
    url: baseURL + path + (queryParams ? +'?' + new URLSearchParams(queryParams).toString() : ''),
    method,
    data,
    headers: getHeaders(headerType),
    params: extraParams || {},
    transformResponse: [(response) => response && JSON.parse(response)],
  };
  return axios(requestConfiguration)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return requestConfiguration?.url === 'https://people-hrms.fissionlabs.com/api/v1/social'
          ? Promise.all([response.data, response.headers])
          : response.data;
      }
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        const { details, message, status, timestamp } = error.response.data;
        toast.error(
          <div>
            <span>{error.response.status}</span>: <span>{message}</span>
          </div>
        );
        throw new ApiException(message, status, details, timestamp);
      } else if (error.request) {
        throw new ApiException('Something went wrong !');
      } else {
        const { message = '' } = error;
        throw new ApiException(message);
      }
    });
};
