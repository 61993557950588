import "./App.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import  AppRoutes  from "./routes";
import ToastWrapper from "./utils/toast-wrapper";
import UserState from "./context/userState";

function App() {
  return (
    <UserState>
      <GoogleOAuthProvider clientId="1037219380694-5aupoe2rciib07h41pivfm0talaav3lm.apps.googleusercontent.com">
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <div className="App">
              <AppRoutes />
              <ToastWrapper />
            </div>
          </PersistGate>
        </Provider>
      </GoogleOAuthProvider>
    </UserState>
  );
}

export default App;
