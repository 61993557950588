/* eslint-disable import/no-extraneous-dependencies */
import { useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Login } from '../pages/auth';
import ProtectedRoutes from './ProtectedRoutes';
import AuthenticatedLayout from './AuthenticatedLayout';
import Employees from '../pages/employees';
import Clients from '../pages/clients';
import Projects from '../pages/projects';
import EmployeeForm from '../pages/employees/EmployeeForm';
import PageNotFound from '../pages/page-not-found';
import QueryBuilderWrapper from '../common-components/query-builder/QueryBuilderWrapper';
import ClientsForm from '../pages/clients/ClientsForm';
import ProjectForm from '../pages/projects/projectForm';
import UserContext from '../context/userContext';
import WrappedWithErrorBoundary from '../common-components/Errors/WrappedWithErrorBoundary';

function AppRoutes() {
  const { userLoggedIn } = useContext(UserContext);

  return (
    <BrowserRouter>
      <Routes>
        {!userLoggedIn && (
          <Route
            path="/"
            element={
              <ErrorBoundary FallbackComponent={WrappedWithErrorBoundary}>
                <Login />
              </ErrorBoundary>
            }
          />
        )}
        <Route element={<ProtectedRoutes userLoggedIn={userLoggedIn} />}>
          <Route
            path="/"
            element={
              <AuthenticatedLayout>
                <ErrorBoundary FallbackComponent={WrappedWithErrorBoundary}>
                  <Employees />
                </ErrorBoundary>
              </AuthenticatedLayout>
            }
          />
          <Route
            path="/queryBuilder"
            element={
              <AuthenticatedLayout>
                <ErrorBoundary FallbackComponent={WrappedWithErrorBoundary}>
                  <QueryBuilderWrapper />
                </ErrorBoundary>
              </AuthenticatedLayout>
            }
          />
          <Route
            path="/addEmployee"
            element={
              <AuthenticatedLayout>
                <ErrorBoundary FallbackComponent={WrappedWithErrorBoundary}>
                  <EmployeeForm />
                </ErrorBoundary>
              </AuthenticatedLayout>
            }
          />
          <Route
            path="/editEmployee/:id"
            element={
              <AuthenticatedLayout>
                <ErrorBoundary FallbackComponent={WrappedWithErrorBoundary}>
                  <EmployeeForm />
                </ErrorBoundary>
              </AuthenticatedLayout>
            }
          />
          <Route
            path="/clients"
            element={
              <AuthenticatedLayout>
                <ErrorBoundary FallbackComponent={WrappedWithErrorBoundary}>
                  <Clients />
                </ErrorBoundary>
              </AuthenticatedLayout>
            }
          />
          <Route
            path="/addClient"
            element={
              <AuthenticatedLayout>
                <ErrorBoundary FallbackComponent={WrappedWithErrorBoundary}>
                  <ClientsForm />
                </ErrorBoundary>
              </AuthenticatedLayout>
            }
          />
          <Route
            path="/editClient/:id"
            element={
              <AuthenticatedLayout>
                <ErrorBoundary FallbackComponent={WrappedWithErrorBoundary}>
                  <ClientsForm />
                </ErrorBoundary>
              </AuthenticatedLayout>
            }
          />
          <Route
            path="/projects"
            element={
              <AuthenticatedLayout>
                <ErrorBoundary FallbackComponent={WrappedWithErrorBoundary}>
                  <Projects />
                </ErrorBoundary>
              </AuthenticatedLayout>
            }
          />
          <Route
            path="/addProject"
            element={
              <AuthenticatedLayout>
                <ErrorBoundary FallbackComponent={WrappedWithErrorBoundary}>
                  <ProjectForm />
                </ErrorBoundary>
              </AuthenticatedLayout>
            }
          />
          <Route
            path="/editProject/:id"
            element={
              <AuthenticatedLayout>
                <ErrorBoundary FallbackComponent={WrappedWithErrorBoundary}>
                  <ProjectForm />
                </ErrorBoundary>
              </AuthenticatedLayout>
            }
          />
          <Route
            path="*"
            element={
              <AuthenticatedLayout>
                <ErrorBoundary FallbackComponent={WrappedWithErrorBoundary}>
                  <PageNotFound />
                </ErrorBoundary>
              </AuthenticatedLayout>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
