import './styles.scss';
import { PAGENOTFOUND } from '../../constants/AppConstants';

export default function PageNotFound() {
  return (
    <div className="error-container">
      <div className="error-message">
        <h1>{PAGENOTFOUND.title}</h1>
        <p>{PAGENOTFOUND.description}</p>
        <button className="back-btn" type="button" onClick={() => window.history.back()}>
          {PAGENOTFOUND.text}
        </button>
      </div>
    </div>
  );
}
