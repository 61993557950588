import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { BiSearchAlt } from 'react-icons/bi';

const defaultProps = {
  tableStyle: {
    height: 500,
  },
  defaultColDef: {
    flex: 1,
    minWidth: 120,
    resizable: true,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  pagination: true,
};

/* eslint-disable react/prop-types */
function DataTable({
  rows,
  columns,
  // rowSelection,
  defaultColDef,
  tableStyle,
  pagination,
  enableGlobalSearch,
  tableTitle,
  components,
  note,
  // callbackFn,
  cellClickedListener,
  height,
  addButtonListener,
  addButton,
  handleCellValueChanged,
}) {
  const gridApiRef = useRef(null);
  const columnApiRef = useRef(null);
  const [rowData, setRowData] = useState(rows);
  const [tableHeight, setTableHeight] = useState(tableStyle);
  const [isSearched, setIsSearched] = useState(false);
  useEffect(() => {
    setRowData(rows);
  }, [rows]);

  useEffect(() => {
    setTableHeight(height ? { height } : { height: 500 });
  }, [height]);

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    columnApiRef.current = params.columnApi;
  };

  // function onClicktextdelete(event) {
  //   gridApiRef.current?.setQuickFilter("");
  // }

  const onFilterTextBoxChanged = useCallback((event) => {
    gridApiRef.current?.setQuickFilter(event.target.value);
    setIsSearched(true);
    if (gridApiRef.current?.getModel().rootNode.childrenAfterFilter.length > 0) {
      gridApiRef.current?.hideOverlay();
    } else {
      gridApiRef.current?.showNoRowsOverlay();
    }
  }, []);

  const onPageSizeChanged = useCallback(() => {
    const { value } = document.getElementById('page-size');
    gridApiRef.current.paginationSetPageSize(Number(value));
  }, []);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(window.innerWidth);
      // window.innerWidth > 800 && setIsSideBarOpen(false);
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenWidth]);

  return (
    <div className="ag-theme-alpine">
      {screenWidth <= 800 ? (
        <div className="row">
          <div className={addButton ? 'col-6' : 'col-12'}>
            {tableTitle && <h5>{tableTitle}</h5>}
          </div>
          {addButton && (
            <div className="col-6 d-flex justify-content-end">
              <Button className="add-button" onClick={addButtonListener}>
                <span>{`+ ${'Add'}`}</span>
              </Button>
            </div>
          )}
          <div className="col-12">
            {enableGlobalSearch && (
              <Form>
                <Form.Group
                  className="py-2 search-bar position-relative"
                  style={{ display: 'flex' }}
                >
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    onChange={onFilterTextBoxChanged}
                  />
                  <BiSearchAlt
                    style={{
                      position: 'absolute',
                      top: '20px',
                      right: '10px',
                      fontSize: '20px',
                      color: 'gray',
                    }}
                  />
                </Form.Group>
              </Form>
            )}
          </div>
        </div>
      ) : (
        <div className="col-12">
          <div className="row">
            <div className="col-4">
              {tableTitle && (
                <>
                  <h5 className="pt-3 m-0 px-2 text-start">{tableTitle}</h5>
                  <p className="ms-1 note">{note}</p>
                </>
              )}
            </div>
            <div className={screenWidth <= 1120 && screenWidth > 800 ? 'col-3' : 'col-4'} />
            <div className={addButton ? 'col-3' : 'col-4'}>
              {enableGlobalSearch && (
                <Form>
                  <Form.Group className="p-2 search-bar" style={{ display: 'flex' }}>
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      onChange={onFilterTextBoxChanged}
                    />
                    <BiSearchAlt
                      style={{
                        position: 'relative',
                        top: '10px',
                        left: '-30px',
                        fontSize: '22px',
                        color: 'gray',
                      }}
                    />
                  </Form.Group>
                </Form>
              )}
            </div>
            {addButton && (
              <div
                className={
                  screenWidth <= 1120 && screenWidth > 800
                    ? 'col-2 p-2 d-flex justify-content-end'
                    : 'col-1 p-2'
                }
              >
                <Button className="add-button" onClick={addButtonListener}>
                  <span>{`+ ${'Add'}`}</span>
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
      <div style={tableHeight} className="position-relative">
        <AgGridReact
          defaultColDef={defaultColDef}
          modules={AllCommunityModules}
          onGridReady={onGridReady}
          pagination={pagination}
          columnDefs={columns}
          paginationPageSize={10}
          rowSelection="single"
          rowData={rowData}
          components={components}
          onCellClicked={cellClickedListener}
          onCellValueChanged={handleCellValueChanged}
          overlayNoRowsTemplate={isSearched ? 'No Records To Match' : 'No Records To Show'}
        />
        {pagination && (
          <div className="example-header px-2">
            <Form.Select
              aria-label="Default select example"
              id="page-size"
              onChange={onPageSizeChanged}
            >
              <option value="10" selected>
                10
              </option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </Form.Select>
          </div>
        )}
      </div>
    </div>
  );
}

DataTable.defaultProps = defaultProps;

export default React.memo(DataTable);
