// eslint-disable-next-line import/no-extraneous-dependencies
import { call, put } from "@redux-saga/core/effects";
import {Types as userInfoAction} from "../../actions/userInfoAction";

export default function* getUserInfo() {
    try {
        const [...payload] = yield call({data: "Hi......!"});
        yield put({
            type: userInfoAction.USER_INFO_SUCCESS,
            payload
        })
    } catch(error) {
        yield put({
            type: userInfoAction.USER_INFO_FAILURE,
        })
    }
}