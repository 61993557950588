import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import UserContext from './userContext';

export default function UserState({ children }) {
  const [userLoggedIn, setUserLoggedIn] = useState(JSON.parse(localStorage.getItem('accessToken')));

  const value = useMemo(() => ({ userLoggedIn, setUserLoggedIn }), [userLoggedIn, setUserLoggedIn]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

UserState.propTypes = {
  children: PropTypes.node.isRequired,
};
