import { Toaster } from 'react-hot-toast';

export default function ToastWrapper() {
  return (
    <div>
      <Toaster
        position="top-right"
        toastOptions={{
          style: {
            minWidth: '250px',
            backgroundColor: '#fef7f2',
          },
          success: {
            theme: {
              primary: 'green',
            },
          },
        }}
      />
    </div>
  );
}
