import { makeAPICall } from './api';
import { ApiPath, HttpMethods } from './api/Definitions';

export const getAllEmployees = () => makeAPICall(ApiPath.getAllEmployees, HttpMethods.get, 'auth');

export const getEmployeeById = (id) =>
  makeAPICall(`${ApiPath.getAllEmployees}/${id}`, HttpMethods.get, 'auth');

export const createEmployee = (data) =>
  makeAPICall(`${ApiPath.getAllEmployees}/create`, HttpMethods.post, 'auth', data);

export const editEmployee = (data, id) =>
  makeAPICall(`${ApiPath.getAllEmployees}/${id}`, HttpMethods.put, 'auth', data);

export const getAllSkills = () => makeAPICall(ApiPath.getAllSkills, HttpMethods.get, 'auth');

export const getAllPractices = () => makeAPICall(ApiPath.getAllPractices, HttpMethods.get, 'auth');

export const getAllDepartments = () =>
  makeAPICall(ApiPath.getAllDepartments, HttpMethods.get, 'auth');

export const getAllDesignations = () =>
  makeAPICall(ApiPath.getAllDesignations, HttpMethods.get, 'auth');

export const getAllRoles = () => makeAPICall(ApiPath.getAllRoles, HttpMethods.get, 'auth');

export const getAllReportingManagers = () =>
  makeAPICall(ApiPath.getAllReportingManagers, HttpMethods.get, 'auth');

export const deleteEmployee = (id) =>
  makeAPICall(`${ApiPath.deleteEmployee}/${id}`, HttpMethods.delete, 'auth');

export const allocateProjectToEmployee = (data) =>
  makeAPICall(ApiPath.getAllProjectAllocations, HttpMethods.post, 'auth', data);

export const editAllocateProject = (data) =>
  makeAPICall(`${ApiPath.getAllProjectAllocations}/${data.id}`, HttpMethods.put, 'auth', data);
