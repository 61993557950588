export default class TableHeaderBuilder {
  constructor(
    cellRenderer = null,
    cellStyle = null,
    field = '',
    headerClass = '',
    headerName = '',
    maxWidth = undefined,
    minWidth = undefined,
    width = undefined,
    hide = false
  ) {
    this.cellRenderer = cellRenderer;
    this.cellStyle = cellStyle;
    this.field = field;
    this.headerClass = headerClass;
    this.headerName = headerName;
    this.maxWidth = maxWidth;
    this.minWidth = minWidth;
    this.width = width;
    this.hide = hide;
    // below fields are  for query builder purpose
    this.name = this.field;
    this.label = this.headerName;
  }
}
