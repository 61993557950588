import { useContext, useState, useEffect } from 'react';
import './Header.scss';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { AiOutlineMenu } from 'react-icons/ai';
import { useNavigate } from 'react-router';
import { NAVBAR } from '../../constants/AppConstants';
import fissionLogo from '../../assets/fission-logo.svg';
import userIcon from '../../assets/UserIcon.svg';
import { logOutUser } from '../../pages/auth';
import UserContext from '../../context/userContext';
// import PropTypes from "prop-types";

// eslint-disable-next-line react/prop-types
function Header({ setIsSideBarOpen, isSideBarOpen }) {
  const [user, setUser] = useState('');
  const { setUserLoggedIn } = useContext(UserContext);
  const navigate = useNavigate();
  const accountClassname = 'account-details';
  const menuClassname = `menu-container`;

  useEffect(() => {
    setUser(localStorage.getItem('username'));
  }, []);

  return (
    <Navbar className="shadow-sm sticky-top navbar header-component">
      <div
        className={menuClassname}
        onClick={() => setIsSideBarOpen(!isSideBarOpen)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setIsSideBarOpen(!isSideBarOpen);
          }
        }}
        role="button"
        tabIndex={0}
      >
        <AiOutlineMenu />
      </div>
      <Navbar.Brand className="logo-box">
        <img src={fissionLogo} alt="#" className="fission-logo mt-0" />
      </Navbar.Brand>
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end collapse">
        <Nav className="account-details-desktop">
          <img src={userIcon} className="userIcon" alt="" />
          <NavDropdown title={user} id="basic-nav-dropdown">
            <NavDropdown.Item onClick={() => logOutUser(navigate, setUserLoggedIn)}>
              {NAVBAR.logOut}
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>

        <Nav className={accountClassname}>
          <NavDropdown
            title={<img src={userIcon} className="userIcon" alt="" />}
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item className="text-center text-wrap">Admin --Name</NavDropdown.Item>
            <NavDropdown.Item
              className="text-center text-danger"
              onClick={() => logOutUser(navigate, setUserLoggedIn)}
            >
              {NAVBAR.logOut}
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

// Header.propTypes = {
//   setIsSideBarOpen: PropTypes.func.isRequired,
//   isSideBarOpen: PropTypes.bool.isRequired,
// };

export default Header;
