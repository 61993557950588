import EmployeeTable from './EmployeeTable';

function Employees() {
  // const dispatch = useDispatch();
  // const { userInfoRequest, userInfoSuccess } = useSelector(
  //   (state) => state?.userInfoData
  // );

  // useEffect(() => {
  //   console.log(userInfoSuccess);
  // }, [userInfoSuccess]);
  // dispatch(userInfoReduxActions.userInfoRequest());

  return <EmployeeTable />;
}

export default Employees;
