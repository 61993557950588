import { makeAPICall } from './api';
import { ApiPath, HttpMethods } from './api/Definitions';

export const getAllProjectAllocations = () =>
  makeAPICall(ApiPath.getAllProjectAllocations, HttpMethods.get, 'auth');

export const createProjectAllocation = (data) =>
  makeAPICall(ApiPath.getAllProjectAllocations, HttpMethods.post, 'auth', data);

export const getProjectAllocationById = (id) =>
  makeAPICall(`${ApiPath.getAllProjectAllocations}/${id}`, HttpMethods.get, 'auth');

export const updateProjectAllocation = (data, id) =>
  makeAPICall(`${ApiPath.getAllProjectAllocations}/${id}`, HttpMethods.put, 'auth', data);

export const getAllRoles = () => makeAPICall(ApiPath.getAllRoles, HttpMethods.get, 'auth');

export const deleteProjectAllocation = (id) =>
  makeAPICall(`${ApiPath.getAllProjectAllocations}/${id}`, HttpMethods.delete, 'auth');

export const getAllProjectAllocationsByEmpId = (id) =>
  makeAPICall(
    `${ApiPath.getSelectedEmployeeProjectAllocations}?employee=${id}`,
    HttpMethods.get,
    'auth'
  );
