import { useNavigate } from 'react-router';
import { FaEdit, FaTrash } from 'react-icons/fa';
import {
  dateRenderer,
  nameRenderer,
  roleRenderer,
  stateRenderer,
  yesOrNoRenderer,
} from '../../utils/Helper';
import TableHeaderBuilder from '../../utils/TableHeaderBuilder';
import { getAllEmployees } from '../../services/employeesService';
import { getAllProjectAllocationsByEmpId } from '../../services/projectAllocationService';

function useEmployeeColumns(onDeleteClick, setProjectAllocationData) {
  const navigate = useNavigate();
  const shouldShowIcons = false;

  const onEdit = (data) => {
    navigate(`/editEmployee/${data.id}`, { state: data.id });
  };

  const onDelete = (data) => {
    onDeleteClick(data); // Call the onDeleteClick callback
  };

  const actionsRenderer = (params) => (
    <>
      <FaEdit onClick={() => onEdit(params?.data)} className="edit-icon" />
      {shouldShowIcons && (
        <FaTrash onClick={() => onDelete(params?.data)} className="delete-icon ms-1" />
      )}
    </>
  );

  const handleClick = (flId) => {
    const orgId = flId;
    getAllEmployees().then((res) => {
      const employeeId = res.find((value) => value.orgEmpId === orgId).id;
      getAllProjectAllocationsByEmpId(employeeId).then((response) => {
        setProjectAllocationData(response || []);
      });
    });
  };

  const displayValueAsLink = (params) => (
    <button
      type="button"
      className="link"
      onClick={() => handleClick(params?.value)}
      title="Click here to display allocations"
    >
      {params?.value}
    </button>
  );

  const columns = [
    new TableHeaderBuilder(displayValueAsLink, null, 'orgEmpId', '', 'FL Id', 90, 150, undefined),
    new TableHeaderBuilder(
      nameRenderer,
      { textAlign: 'center' },
      'reportingManager',
      'my-header-class',
      'Reporting To',
      250,
      200,
      undefined
    ),
    new TableHeaderBuilder(
      null,
      { textAlign: 'start' },
      'firstName',
      '',
      'First Name',
      180,
      250,
      undefined
    ),
    new TableHeaderBuilder(
      null,
      { textAlign: 'start' },
      'lastName',
      '',
      'Last Name',
      140,
      250,
      undefined
    ),
    new TableHeaderBuilder(
      null,
      { textAlign: 'start' },
      'gender',
      '',
      'Gender',
      undefined,
      undefined,
      undefined
    ),
    new TableHeaderBuilder(null, { textAlign: 'start' }, 'type', '', 'Type', 150, 100, undefined),
    new TableHeaderBuilder(
      null,
      { textAlign: 'center' },
      'contactNumber',
      'my-header-class',
      'Contact Number',
      300,
      210,
      undefined
    ),
    new TableHeaderBuilder(
      null,
      { textAlign: 'start' },
      'designation',
      '',
      'Designation',
      350,
      300,
      undefined
    ),
    new TableHeaderBuilder(
      roleRenderer,
      { textAlign: 'start' },
      'roles',
      '',
      'Role',
      250,
      150,
      undefined
    ),
    new TableHeaderBuilder(
      nameRenderer,
      { textAlign: 'center' },
      'department',
      'my-header-class',
      'Department',
      250,
      200,
      undefined
    ),
    new TableHeaderBuilder(
      null,
      { textAlign: 'center' },
      'location',
      'my-header-class',
      'Location',
      250,
      150,
      undefined
    ),
    new TableHeaderBuilder(
      null,
      { textAlign: 'center' },
      'email',
      'my-header-class',
      'Email ID',
      350,
      300,
      undefined
    ),
    new TableHeaderBuilder(
      dateRenderer,
      { textAlign: 'center' },
      'fissionStartDate',
      'my-header-class',
      'Joining Date',
      350,
      160,
      undefined
    ),
    new TableHeaderBuilder(
      dateRenderer,
      { textAlign: 'center' },
      'expStartDate',
      'my-header-class',
      'Experience Date',
      300,
      170,
      undefined
    ),
    new TableHeaderBuilder(
      null,
      { textAlign: 'center' },
      'status',
      'my-header-class',
      'Status',
      250,
      170,
      undefined
    ),
    new TableHeaderBuilder(
      null,
      { textAlign: 'start' },
      'practice',
      '',
      'Practice',
      250,
      200,
      undefined
    ),
    new TableHeaderBuilder(
      stateRenderer,
      { textAlign: 'center' },
      'active',
      'my-header-class',
      'Current State',
      250,
      150,
      undefined
    ),
    new TableHeaderBuilder(
      yesOrNoRenderer,
      { textAlign: 'center' },
      'billable',
      'my-header-class',
      'Is Billable',
      250,
      150,
      undefined
    ),
    new TableHeaderBuilder(
      actionsRenderer,
      { textAlign: 'center' },
      'actions',
      'my-header-class',
      'Actions',
      200,
      150,
      undefined
    ),
  ];

  return columns;
}

export default useEmployeeColumns;
