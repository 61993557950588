import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isArray } from 'lodash';
import {
  allocateTypes,
  projectAllocationForm,
  BUTTONS,
  commonValues,
  arrayOfRoles,
} from '../../constants/AppConstants';
import { getAllProjects } from '../../services/projectService';

/* eslint-disable react/prop-types */
function ProjectAllocationForm({
  onModalClosePopup,
  setModal,
  onProjectAllocation,
  selectedProjectAllocation,
  // isEdit,
}) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      active: 'yes',
    },
  });
  const [projectsList, setProjectsList] = useState([]);
  useEffect(() => {
    getAllProjects().then((res) => {
      setProjectsList(isArray(res) ? res : []);
    });
  }, []);

  const location = useLocation();
  useEffect(() => {
    if (
      selectedProjectAllocation &&
      Object.keys(selectedProjectAllocation).length > 0 &&
      location.pathname !== '/addEmployee'
    ) {
      const allocationFields = {
        projects: selectedProjectAllocation?.projectId,
        role: selectedProjectAllocation?.role,
        allocateType: selectedProjectAllocation?.jobType,
        utilization: selectedProjectAllocation?.utilization,
        startDate: selectedProjectAllocation?.startDate?.slice(0, 10),
        endDate: selectedProjectAllocation?.endDate?.slice(0, 10),
        notes: selectedProjectAllocation?.notes,
        active: selectedProjectAllocation?.active === true ? 'yes' : 'no',
        billable: selectedProjectAllocation?.billable === true ? 'yes' : 'no',
        primary: selectedProjectAllocation?.primary === true ? 'yes' : 'no',
      };

      reset(allocationFields);
    }
  }, [selectedProjectAllocation, location, projectsList, reset]);

  const onSubmit = (data) => {
    const projectAllocationReqObj = {
      projectId: data.projects,
      role: data.role,
      jobType: data.allocateType,
      utilization: data.utilization,
      startDate: data.startDate ? new Date(data.startDate).toISOString() : null,
      endDate: data.endDate ? new Date(data.endDate).toISOString() : null,
      notes: data.notes ? data.notes : '',
      active: data.active === 'yes',
      billable: data.billable === 'yes',
      primary: data.primary === 'yes',
      id: selectedProjectAllocation?.id ? selectedProjectAllocation?.id : undefined,
    };
    onProjectAllocation(projectAllocationReqObj);
    setModal(false);
  };

  return (
    <>
      {/* eslint-disable react/jsx-props-no-spreading */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row p-3">
          <div className="col-md-6 col-sm-12 mb-3">
            <label htmlFor="projects">{projectAllocationForm.labels.projectName}</label>{' '}
            <span className="error">*</span>
            <select
              id="projects"
              {...register('projects', { required: true })}
              className="form-select"
            >
              <option value="">{commonValues.select}</option>
              {projectsList.map((projects) => (
                <option key={projects.id} value={projects.id}>
                  {projects.name}
                </option>
              ))}
            </select>
            {errors.projects && (
              <p className="error mb-0">{projectAllocationForm.validations.project}</p>
            )}
          </div>

          <div className="col-md-6 col-sm-12 mb-3">
            <label htmlFor="role">{projectAllocationForm.labels.role}</label>{' '}
            <span className="error">*</span>
            <select id="role" {...register('role', { required: true })} className="form-select">
              <option value="">{commonValues.select}</option>
              {arrayOfRoles?.map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </select>
            {errors.role && <p className="error mb-0">{projectAllocationForm.validations.role}</p>}
          </div>

          <div className="col-md-6 col-sm-12 mb-3">
            <label htmlFor="allocateType">{projectAllocationForm.labels.allocateType}</label>{' '}
            <span className="error">*</span>
            <select
              id="allocateType"
              {...register('allocateType', { required: true })}
              className="form-select"
            >
              <option value="">{commonValues.select}</option>
              {allocateTypes?.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
            {errors.allocateType && (
              <p className="error mb-0">{projectAllocationForm.validations.allocateType}</p>
            )}
          </div>

          <div className="col-md-6 col-sm-12 mb-3">
            <label htmlFor="utilization">{projectAllocationForm.labels.utilization}</label>{' '}
            <span className="error">*</span>
            <input
              type="number"
              id="utilization"
              className="form-control"
              placeholder="Percentage"
              {...register('utilization', {
                required: 'Utilization is required',
                pattern: {
                  value: /^(\d{1,2}|100)(\.\d{1,2})?$/,
                  message: 'Utilization must be a percentage between 0 and 100',
                },
              })}
            />
            {errors.utilization && <p className="error mb-0">{errors.utilization.message}</p>}
          </div>

          <div className="col-md-6 col-sm-12 mb-3">
            <label htmlFor="startDate">{projectAllocationForm.labels.startDate}</label>{' '}
            <span className="error">*</span>
            <input
              type="date"
              id="startDate"
              className="form-control session-date"
              defaultValue={new Date().toLocaleDateString('en-CA')}
              placeholder="startDate"
              {...register('startDate', {
                required: true,
              })}
            />
            {errors.startDate && (
              <p className="error mb-0">{projectAllocationForm.validations.startDate}</p>
            )}
          </div>

          <div className="col-md-6 col-sm-12 mb-3">
            <label htmlFor="endDate">{projectAllocationForm.labels.endDate}</label>
            <input
              type="date"
              id="endDate"
              className="form-control session-date"
              placeholder="endDate"
              {...register('endDate')}
            />
          </div>

          <div className="col-md-12 col-sm-12 mb-3">
            <label htmlFor="notes">{projectAllocationForm.labels.notes}</label>
            <textarea
              id="notes"
              {...register('notes', { maxLength: 750 })}
              className="form-control"
              placeholder="Write a Note"
            />
            {errors.notes && (
              <p className="error mb-0">{projectAllocationForm.validations.notes}</p>
            )}
          </div>

          <div className="col-md-4 col-sm-12 text-center mb-3">
            <div className="border rounded p-2">
              <label htmlFor="active" className="me-5">
                {projectAllocationForm.labels.active}
              </label>
              <label htmlFor="active">
                <input type="radio" value="yes" {...register('active')} className="me-1" />
                {commonValues.yes}
              </label>
              <label htmlFor="active" className="ms-4">
                <input
                  type="radio"
                  value="no"
                  {...register('active', { required: true })}
                  className="me-1"
                />
                {commonValues.no}
              </label>
            </div>
            {errors.active && (
              <p className="error mb-0">{projectAllocationForm.validations.active}</p>
            )}
          </div>
          <div className="col-md-4 col-sm-12 text-center mb-3">
            <div className="border rounded p-2">
              <label htmlFor="billable" className="me-5">
                {projectAllocationForm.labels.billable}
              </label>
              <label htmlFor="billable">
                <input
                  type="radio"
                  value="yes"
                  {...register('billable', { required: true })}
                  className="me-1"
                />
                {commonValues.yes}
              </label>
              <label htmlFor="billable" className="ms-4">
                <input
                  type="radio"
                  value="no"
                  {...register('billable', { required: true })}
                  className="me-1"
                />
                {commonValues.no}
              </label>
            </div>
            {errors.billable && (
              <p className="error mb-0">{projectAllocationForm.validations.billable}</p>
            )}
          </div>
          <div className="col-md-4 col-sm-12 text-center mb-3">
            <div className="border rounded p-2">
              <label htmlFor="primary" className="me-5">
                {projectAllocationForm.labels.primary}
              </label>
              <label htmlFor="primary">
                <input
                  type="radio"
                  value="yes"
                  {...register('primary', { required: true })}
                  className="me-1"
                />
                {commonValues.yes}
              </label>
              <label htmlFor="primary" className="ms-4">
                <input
                  type="radio"
                  value="no"
                  {...register('primary', { required: true })}
                  className="me-1"
                />
                {commonValues.no}
              </label>
            </div>
            {errors.primary && (
              <p className="error mb-0">{projectAllocationForm.validations.primary}</p>
            )}
          </div>

          <div className="d-flex justify-content-center mt-4">
            <button type="submit" className="btn submit-btn">
              {BUTTONS.submit}
            </button>
            <button type="button" className="btn cancel-btn ms-4" onClick={onModalClosePopup}>
              {BUTTONS.cancel}
            </button>
          </div>
        </div>
      </form>
      {/* eslint-enable react/jsx-props-no-spreading */}
    </>
  );
}

// ProjectAllocationForm.propTypes = {
//   onModalClosePopup: PropTypes.func.isRequired,
//   setModal: PropTypes.func.isRequired,
//   onProjectAllocation: PropTypes.func.isRequired,
//   selectedProjectAllocation: PropTypes.shape({
//     id: PropTypes.number.isRequired,
//     projectName: PropTypes.shape({
//       name: PropTypes.string.isRequired
//     }).isRequired,
//     role: PropTypes.shape({
//       role: PropTypes.string.isRequired
//     }).isRequired,
//     allocationType: PropTypes.string.isRequired,
//     utilization: PropTypes.number.isRequired,
//     startDate: PropTypes.string.isRequired,
//     endDate: PropTypes.string.isRequired,
//     notes: PropTypes.string.isRequired,
//     isActive: PropTypes.bool.isRequired,
//     isBillable: PropTypes.bool.isRequired,
//     isPrimary: PropTypes.bool.isRequired
//   }),
//   isEdit: PropTypes.bool.isRequired
// };

export default ProjectAllocationForm;
