import { useNavigate } from 'react-router';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { dateRenderer } from '../../utils/Helper';
import TableHeaderBuilder from '../../utils/TableHeaderBuilder';

function useClientColumns(onDeleteClick) {
  const navigate = useNavigate();
  const shouldShowIcons = false;

  const onEdit = (data) => {
    navigate(`/editClient/${data.id}`, { state: data.id });
  };

  const onDelete = (data) => {
    onDeleteClick(data);
  };

  const actionsRenderer = (params) => (
    <>
      <FaEdit onClick={() => onEdit(params?.data)} className="edit-icon" />
      {shouldShowIcons && (
        <FaTrash onClick={() => onDelete(params?.data)} className="delete-icon ms-1" />
      )}
    </>
  );

  const columns = [
    new TableHeaderBuilder(null, null, 'name', '', 'Name', 200, 180, undefined),
    new TableHeaderBuilder(
      null,
      { textAlign: 'center' },
      'status',
      'my-header-class',
      'Status',
      undefined,
      undefined,
      undefined
    ),
    new TableHeaderBuilder(
      dateRenderer,
      { textAlign: 'center' },
      'startDate',
      'my-header-class',
      'Start Date',
      undefined,
      undefined,
      undefined
    ),
    new TableHeaderBuilder(
      dateRenderer,
      { textAlign: 'center' },
      'endDate',
      'my-header-class',
      'End Date',
      undefined,
      undefined,
      undefined
    ),
    new TableHeaderBuilder(
      null,
      { textAlign: 'center' },
      'managerName',
      'my-header-class',
      'Client Manager',
      undefined,
      undefined,
      undefined
    ),
    new TableHeaderBuilder(
      actionsRenderer,
      { textAlign: 'center' },
      'actions',
      'my-header-class',
      'Actions',
      undefined,
      undefined,
      undefined
    ),
  ];

  return columns;
}

export default useClientColumns;
