/* eslint-disable react/prop-types */
import './WrappedWithErrorBoundary.scss';

function WrappedWithErrorBoundary({ error }) {
  return (
    <div role="alert" className="fallback-container">
      <div className="error-message">
        <h2>Something went wrong!!</h2>
        <pre>{error.message}</pre>
        <h5>
          Contact Developer{' '}
          <a href="/" className="dev-address">
            abcdfe@fissionlabs.com
          </a>
        </h5>
      </div>
    </div>
  );
}
export default WrappedWithErrorBoundary;
