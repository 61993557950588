import './Card.scss';

/* eslint-disable react/prop-types */
function Card(props) {
  const { className, children } = props;
  const classes = `batches-card ${className}`;
  return <div className={classes}>{children}</div>;
}

export default Card;
