/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Typeahead } from 'react-bootstrap-typeahead';
import Card from '../../common-components/card/Card';
import { commonValues, BUTTONS, clientForm, statusDropdown } from '../../constants/AppConstants';
import BreadCrumb from '../../common-components/bread-crumb/BreadCrumb';
import {
  getAllManagers,
  getClientById,
  updateClient,
  createClient,
} from '../../services/clientService';

function ClientsForm() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    control,
  } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const clientId = location.state;
  const [selectedClient, setSelectedClient] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [state, setState] = useState([]);
  const [managers, setManagers] = useState([]);

  useEffect(() => {
    getAllManagers().then((response) => {
      setManagers(response || []);
    });
  }, []);

  useEffect(() => {
    if (location.state) {
      setIsEdit(true);
      getClientById(location.state).then((response) => {
        setSelectedClient(response);
      });
    }
  }, [location.state]);

  useEffect(() => {
    // Edit client
    if (selectedClient && location.pathname !== '/addClient') {
      setState([
        { id: 1, name: 'Clients', route: '/clients', isLink: true },
        { id: 2, name: 'Edit Client', route: '', isLink: false },
      ]);
      const fields = {
        name: selectedClient?.name,
        status: selectedClient?.status,
        startDate: selectedClient?.startDate?.slice(0, 10),
        endDate: selectedClient?.endDate?.slice(0, 10),
        clientManager: [selectedClient?.managerName],
      };

      reset(fields);
    } else {
      // setIsEdit(false);
      setState([
        { id: 1, name: 'Clients', route: '/clients', isLink: true },
        { id: 2, name: 'Add Client', route: '', isLink: false },
      ]);
    }
  }, [selectedClient, reset, location, isEdit]);

  const onSubmit = (data) => {
    const reqObj = {
      name: data.name,
      status: data.status,
      startDate: data.startDate ? new Date(data.startDate).toISOString() : null,
      endDate: data.endDate ? new Date(data.endDate).toISOString() : null,
      managerId: data.clientManager[0].id,
    };
    if (reqObj) {
      if (isEdit) {
        updateClient(reqObj, clientId).then((response) => {
          if (response) {
            toast.success(clientForm.messages.update);
            navigate('/clients');
          } else {
            toast.error(response?.message);
          }
        });
      } else {
        createClient(reqObj).then((response) => {
          if (response) {
            toast.success(clientForm.messages.create);
            navigate('/clients');
          } else {
            toast.error(response?.message);
          }
        });
      }
    }
  };

  const handleCancelEvent = () => {
    navigate('/clients');
  };

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  return (
    <div className="px-1">
      <BreadCrumb data={state} />
      <div className="d-flex justify-content-between">
        <h4 className="col-4 pt-3">
          {isEdit ? 'Edit' : 'Add'} {clientForm.title}
        </h4>
      </div>
      <Card className="mt-3 px-4 py-3">
        {/* eslint-disable react/jsx-props-no-spreading */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="name">{clientForm.labels.name}</label>{' '}
              <span className="error">*</span>
              <input
                id="name"
                type="text"
                className="form-control"
                placeholder="Name"
                {...register('name', { required: true, maxLength: 20 })}
              />
              {errors.name && <p className="error mb-0">{clientForm.validations.name}</p>}
            </div>
            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="status">{clientForm.labels.status}</label>{' '}
              <span className="error">*</span>
              <select
                id="status"
                {...register('status', { required: true })}
                className="form-select"
              >
                <option value="">{commonValues.select}</option>
                {statusDropdown?.map((status) => (
                  <option key={status.id} value={status.label}>
                    {status.label}
                  </option>
                ))}
              </select>
              {errors.status && <p className="error mb-0">{clientForm.validations.status}</p>}
            </div>
            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="startDate">{clientForm.labels.startDate}</label>{' '}
              <span className="error">*</span>
              <input
                type="date"
                id="startDate"
                className="form-control session-date"
                disabled={isEdit}
                defaultValue={formattedDate}
                {...register('startDate', {
                  required: true,
                })}
              />
              {errors.startDate && <p className="error mb-0">{clientForm.validations.date}</p>}
            </div>
            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="endDate">{clientForm.labels.endDate}</label>{' '}
              <span className="error">*</span>
              <input
                type="date"
                id="endDate"
                className="form-control session-date"
                {...register('endDate', {
                  disabled: watch('status') !== 'Completed',
                  required: true,
                })}
              />
              {errors.endDate && <p className="error mb-0">{clientForm.validations.date}</p>}
            </div>
            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="clientManager" className="form-label">
                {clientForm.labels.clientManager}
              </label>{' '}
              <span className="error">*</span>
              <Controller
                name="clientManager"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Typeahead
                    id="clientManager"
                    labelKey="name"
                    onChange={(selected) => field.onChange(selected)}
                    options={managers}
                    placeholder="Select a Manager"
                    selected={field.value}
                  />
                )}
              />
              {errors.clientManager && (
                <p className="error mb-0">{clientForm.validations.clientManager}</p>
              )}
            </div>
            <div className="d-flex justify-content-center pt-3">
              <button
                type="submit"
                className="btn submit-btn"
                disabled={isEdit && selectedClient?.status === 'Completed'}
              >
                {BUTTONS.submit}
              </button>
              <button type="button" className="btn cancel-btn ms-4" onClick={handleCancelEvent}>
                {BUTTONS.cancel}
              </button>
            </div>
          </div>
        </form>
        {/* eslint-enable react/jsx-props-no-spreading */}
      </Card>
    </div>
  );
}

export default ClientsForm;
