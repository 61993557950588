import { makeAPICall } from './api';
import { ApiPath, HttpMethods } from './api/Definitions';

export const getAllClients = () => makeAPICall(ApiPath.getClient, HttpMethods.get, 'auth');

export const getClientById = (id) =>
  makeAPICall(`${ApiPath.getClient}/${id}`, HttpMethods.get, 'auth');

export const createClient = (data) =>
  makeAPICall(ApiPath.getClient, HttpMethods.post, 'auth', data);

export const getAllManagers = () => makeAPICall(ApiPath.getManagers, HttpMethods.get, 'auth');

export const updateClient = (data, id) =>
  makeAPICall(`${ApiPath.getClient}/${id}`, HttpMethods.put, 'auth', data);

export const deleteClient = (id) =>
  makeAPICall(`${ApiPath.getClient}/${id}`, HttpMethods.delete, 'auth');
