const getHeaders = (headerType) => {
  switch (headerType) {
    case 'auth':
      return {
        // contentType: "application/json",
        'X-Org-Id': 'FL',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('accessToken'))}`,
      };
    default:
      return {};
  }
};

export default getHeaders;
