import { BsPeopleFill } from 'react-icons/bs';
import { FaPeopleCarry, FaProjectDiagram } from 'react-icons/fa';

export const NAVBAR = {
  yourProfile: 'Your profile',
  logOut: 'Log out',
};

export const LOGIN = {
  header: 'We are More than Just A Company',
  description:
    'We harness innovation and engineering excellence to create symphony between business processes and underlying technologies.',
  title: 'Please login to your account',
  text: 'Login With Google',
};

export const PAGENOTFOUND = {
  title: 'Oops! Page not found',
  description:
    'The page you are looking for might have been removed or is temporarily unavailable.',
  text: 'Go back',
};

export const BUTTONS = {
  submit: 'Submit',
  cancel: 'Cancel',
  allocateProject: 'Allocate Project',
  addProject: 'Add Project',
};

export const commonValues = {
  yes: 'Yes',
  no: 'No',
  select: '-Select-',
  successful: 'SUCCESSFUL',
};

export const notes = {
  allocationNote: '(Note: Displays Individual Employee allocations)',
};

export const SIDEBAR = [
  {
    id: 1,
    key: 'employees',
    label: 'Employees',
    path: '/',
    icon: <BsPeopleFill />,
    className: {
      mobileView: 'pt-1 sidebar-styles mobile-view-sidebar-details employees-tab',
      desktopView: 'mt-3 sidebar-styles employees-tab',
    },
  },
  {
    id: 2,
    key: 'clients',
    label: 'Clients',
    path: '/clients',
    icon: <FaPeopleCarry />,
    className: {
      mobileView: 'pt-1 sidebar-styles mobile-view-sidebar-details clients-tab',
      desktopView: 'mt-3 sidebar-styles clients-tab',
    },
  },
  {
    id: 3,
    key: 'projects',
    label: 'Projects',
    path: '/projects',
    icon: <FaProjectDiagram />,
    className: {
      mobileView: 'pt-1 sidebar-styles mobile-view-sidebar-details projects-tab',
      desktopView: 'mt-3 sidebar-styles projects-tab',
    },
  },
];

export const employeeForm = {
  title1: 'Add Employee',
  title2: 'Edit Employee',
  labels: {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    fissionId: 'Fission ID',
    role: 'Role',
    designation: 'Designation',
    nickname: 'Nick Name',
    gender: 'Gender',
    type: 'Type',
    department: 'Department',
    joiningDate: 'Date Of Joining',
    experienceDate: 'Experience Start Date',
    practice: 'Practice',
    status: 'Employee Status',
    reportingManager: 'Reporting Manager',
    location: 'Location',
    primarySkill: 'Primary Skill',
    otherSkills: 'Other Skills',
    contactNumber: 'Contact Number',
    active: 'Active',
    billable: 'Billable',
    notes: 'Notes',
  },
  validations: {
    firstName: 'First Name is required and must be less than 20 characters',
    lastName: 'Last Name is required and must be less than 20 characters',
    role: 'Please select a Role',
    designation: 'Please select the Designation',
    gender: 'Please select a Gender',
    type: 'Please select a Type',
    department: 'Please select a Department',
    joiningDate: 'Date Of Joining is required',
    experienceDate: 'Experience Start Date is required',
    practice: 'Please select a Practice',
    status: 'Please select an Employee Status',
    reportingManager: 'Please select a Reporting Manager',
    primarySkill: 'Please select Primary Skills',
    otherSkills: 'Please select Other Skills',
    location: 'Please select a Location',
    active: 'Please select an Option',
    billable: 'Please select an Option',
    notes: 'can add upto 750 characters',
  },
  messages: {
    create: 'Employee Created successfully',
    update: 'Employee Updated successfully',
  },
};

export const projectAllocationForm = {
  tableTitle: 'Project Allocations',
  labels: {
    projectName: 'Project Name',
    role: 'Role',
    allocateType: 'Allocate Type',
    utilization: 'Utilization',
    startDate: 'Start Date',
    endDate: 'End Date',
    notes: 'Notes',
    active: 'Active',
    billable: 'Billable',
    primary: 'Primary',
  },
  validations: {
    project: 'Please select a Project',
    role: 'Please select a Role',
    allocateType: 'Please select a Type',
    startDate: 'Start Date is required',
    active: 'Please select an Option',
    billable: 'Please select an Option',
    primary: 'Please select an Option',
    notes: 'can add upto 750 characters',
  },
  messages: {
    create: 'Project Allocated successfully',
    update: 'Project Allocation Updated successfully',
  },
};

export const arrayOfGenders = ['Female', 'Male', 'Other'];
export const arrayOfTypes = ['Employee', 'Contractor', 'Intern'];
export const arrayOfStatus = ['Bench', 'BenchReserved', 'InProject', 'Training', 'Maternity', 'NA'];
export const allocateTypes = ['Full time', 'Part time', 'Shadow'];
export const arrayOfLocations = ['Hyderabad', 'Vijayawada', 'USA'];
export const arrayOfRoles = ['Manager', 'Team Member', 'Team Incharge'];

export const statusDropdown = [
  { id: 1, label: 'InProgress' },
  { id: 2, label: 'Completed' },
  { id: 3, label: 'Prospect' },
];

export const clientForm = {
  title: 'Client',
  labels: {
    name: 'Name',
    status: 'Status',
    startDate: 'Start Date',
    endDate: 'End Date',
    clientManager: 'Client Manager',
  },
  validations: {
    date: 'Please Select a Valid Date',
    status: 'Please Select a Status',
    name: 'Please Enter Valid Name',
    clientManager: 'Please Select a Client Manager',
  },
  messages: {
    create: 'Client Created successfully',
    update: 'Client Updated successfully',
  },
};

export const projectForm = {
  title: 'Project',
  labels: {
    name: 'Name',
    status: 'Status',
    startDate: 'Start Date',
    endDate: 'End Date',
    type: 'Type',
    projectManager: 'Project Manager',
    allocateClient: 'Allocate Client',
  },
  dropDowns: {
    type: [
      { id: 1, label: 'T&M' },
      { id: 2, label: 'Fixed Bid' },
    ],
  },
  validations: {
    date: 'Please Select a Valid Date',
    status: 'Please Select a Status',
    type: 'Please Select Project Type',
    name: 'Please Enter Valid Name',
    projectManager: 'Please Select a project Manager',
    allocateClient: 'Please Allocate a client',
  },
  messages: {
    create: 'Project Created successfully',
    update: 'Project Updated successfully',
  },
};
