import './Sidebar.scss';
import { NavLink, useLocation } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import { useEffect } from 'react';
import { SIDEBAR } from '../../constants/AppConstants';
import { getActiveTabByRouteName } from '../../utils/Helper';

/* eslint-disable react/prop-types */
function Sidebar({ isSideBarOpen, screenWidth, setIsSideBarOpen }) {
  const route = useLocation();
  const sidebarClassname = `sidebar-details ${isSideBarOpen && 'sidebar-details-open'}`;
  const asideClassName = `page-sidebar ${isSideBarOpen && 'open'}`;
  const mobileViewSidebarClassname = `sidebar-mobile ${isSideBarOpen && 'sidebar-mobile-open'}`;

  useEffect(() => {
    getActiveTabByRouteName(route.pathname);
  }, [route.pathname]);

  return screenWidth <= 800 ? (
    <div className={mobileViewSidebarClassname}>
      <Navbar className="col-12 p-0">
        <Nav className="d-flex align-self-start  flex-column w-100">
          {SIDEBAR.map((item) => (
            <NavLink
              to={item.path}
              key={item.id}
              className={item.className.mobileView}
              onClick={() => setIsSideBarOpen(false)}
            >
              <div className="py-2 d-flex justify-content-center">{item.icon}</div>{' '}
              <div className="py-2">{item.label}</div>
            </NavLink>
          ))}
        </Nav>
      </Navbar>
    </div>
  ) : (
    <aside className={asideClassName}>
      <div className="sidebar">
        <Navbar className="col-12 p-0">
          <Nav className="d-flex align-self-start  flex-column w-100">
            {SIDEBAR.map((item) => (
              <NavLink
                to={item.path}
                key={item.id}
                onClick={() => setIsSideBarOpen(false)}
                className={item.className.desktopView}
              >
                <div className="py-2 text-start text-center">{item.icon}</div>
              </NavLink>
            ))}
          </Nav>
        </Navbar>
      </div>
      <div className={sidebarClassname}>
        <Navbar className="col-12">
          <Nav className="d-flex align-self-start flex-column w-100">
            {SIDEBAR.map((item) => (
              <NavLink
                to={item.path}
                key={item.id}
                className={item.className.desktopView}
                onClick={() => setIsSideBarOpen(false)}
              >
                <div className="text-start py-2 px-1">{item.label}</div>
              </NavLink>
            ))}
          </Nav>
        </Navbar>
      </div>
    </aside>
  );
}

export default Sidebar;
