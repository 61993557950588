export const HttpMethods = {
  get: 'GET',
  post: 'POST',
  delete: 'DELETE',
  put: 'PUT',
  patch: 'PATCH',
};

export const ApiPath = {
  validateUserLogin: '/api/v1/social',
  getAllEmployees: '/api/v1/employee',
  getAllSkills: '/api/v1/skills',
  getAllPractices: '/api/v1/practices',
  getAllDesignations: '/api/v1/designations',
  getAllDepartments: '/api/v1/departments',
  getAllRoles: '/api/v1/applicationrole',
  getAllReportingManagers: '/api/v1/employee/reportingmanagers',
  deleteEmployee: '/api/v1/employee',
  getClient: '/api/v1/client',
  getManagers: '/api/v1/managers',
  getProject: '/api/v1/project',
  getAllProjectAllocations: '/api/v1/allocations',
  getSelectedEmployeeProjectAllocations: '/api/v1/allocations/employee',
};
