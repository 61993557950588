import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Typeahead } from 'react-bootstrap-typeahead';
import Card from '../../common-components/card/Card';
import { commonValues, BUTTONS, statusDropdown, projectForm } from '../../constants/AppConstants';
import BreadCrumb from '../../common-components/bread-crumb/BreadCrumb';
import { getAllManagers, getAllClients } from '../../services/clientService';
import { getProjectById, createProject, updateProject } from '../../services/projectService';

function ProjectForm() {
  const [managers, setManagers] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    control,
  } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const projectId = location.state;
  const [isEdit, setIsEdit] = useState(false);
  const [state, setState] = useState([]);

  useEffect(() => {
    getAllManagers().then((response) => {
      setManagers(response || []);
    });
    getAllClients().then((response) => setClients(response || []));
    if (projectId?.id) {
      setIsEdit(true);
      getProjectById(projectId?.id).then((response) => {
        setSelectedClient(response);
      });
    }
  }, [projectId?.id]);

  useEffect(() => {
    // Edit client
    if (selectedClient && location.pathname !== '/addProject') {
      setState([
        { name: 'Projects', route: '/projects', isLink: true },
        { name: 'Edit project', route: '', isLink: false },
      ]);
      const { name, status, startDate, endDate, manager, type, client } = selectedClient || {};
      const fields = {
        name,
        status,
        startDate: startDate?.slice(0, 10),
        endDate: endDate ? endDate.slice(0, 10) : null,
        projectManager: [manager?.name],
        type,
        allocateClient: client?.id,
      };

      reset(fields);
    } else {
      // setIsEdit(false);
      setState([
        { id: 1, name: 'Projects', route: '/projects', isLink: true },
        { id: 2, name: 'Add project', route: '', isLink: false },
      ]);
    }
  }, [selectedClient, location, isEdit, reset]);

  const onSubmit = (data) => {
    const reqObj = {
      name: data?.name,
      status: data?.status,
      startDate: data?.startDate ? new Date(data.startDate).toISOString() : null,
      endDate: data?.endDate ? new Date(data.endDate).toISOString() : null,
      managerId: data?.projectManager[0].id,
      type: data?.type,
      clientId: data?.allocateClient,
    };
    if (isEdit) {
      updateProject(reqObj, projectId.id).then((response) => {
        if (response) {
          toast.success(projectForm.messages.update);
          navigate('/projects');
        } else {
          toast.error(response?.message);
        }
      });
    } else {
      createProject(reqObj).then((response) => {
        if (response) {
          toast.success(projectForm.messages.create);
          navigate('/projects');
        } else {
          toast.error(response?.message);
        }
      });
    }
  };

  const handleCancelEvent = () => {
    navigate('/projects');
  };

  return (
    <div className="px-1">
      <BreadCrumb data={state} />
      <div className="d-flex justify-content-between">
        <h4 className="col-4 pt-3">
          {isEdit ? 'Edit' : 'Add'} {projectForm.title}
        </h4>
      </div>
      <Card className="mt-3 px-4 py-3">
        {/* eslint-disable react/jsx-props-no-spreading */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="name">{projectForm.labels.name}</label>{' '}
              <span className="error">*</span>
              <input
                id="name"
                type="text"
                className="form-control"
                placeholder="Name"
                {...register('name', { required: true, maxLength: 20 })}
              />
              {errors.name && <p className="error mb-0">{projectForm.validations.name}</p>}
            </div>
            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="status">{projectForm.labels.status}</label>{' '}
              <span className="error">*</span>
              <select
                id="status"
                {...register('status', { required: true })}
                className="form-select"
              >
                <option value="">{commonValues.select}</option>
                {statusDropdown?.map((status) => (
                  <option key={status.id} value={status.label}>
                    {status.label}
                  </option>
                ))}
              </select>
              {errors.status && <p className="error mb-0">{projectForm.validations.status}</p>}
            </div>
            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="startDate">{projectForm.labels.startDate}</label>{' '}
              <span className="error">*</span>
              <input
                type="date"
                id="startDate"
                disabled={isEdit}
                className="form-control session-date"
                defaultValue={new Date().toLocaleDateString('en-CA')}
                {...register('startDate', {
                  required: true,
                })}
              />
              {errors.startDate && <p className="error mb-0">{projectForm.validations.date}</p>}
            </div>
            {/* {watch("status") === "completed" && ( */}
            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="endDate">{projectForm.labels.endDate}</label>{' '}
              <span className="error">*</span>
              <input
                type="date"
                id="endDate"
                className="form-control session-date"
                {...register('endDate', {
                  disabled: watch('status') !== 'Completed',
                  required: true,
                })}
              />
              {errors.endDate && <p className="error mb-0">{projectForm.validations.date}</p>}
            </div>
            {/* )} */}
            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="projectManager">{projectForm.labels.projectManager}</label>{' '}
              <span className="error">*</span>
              <Controller
                name="projectManager"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Typeahead
                    id="projectManager"
                    labelKey="name"
                    onChange={(selected) => field.onChange(selected)}
                    options={managers}
                    placeholder="Select a Manager"
                    selected={field.value}
                  />
                )}
              />
              {errors.projectManager && (
                <p className="error mb-0">{projectForm.validations.projectManager}</p>
              )}
            </div>
            <div className="col-md-6 col-sm-12 mb-3">
              <label htmlFor="type">{projectForm.labels.type}</label>{' '}
              <span className="error">*</span>
              <select id="type" {...register('type', { required: true })} className="form-select">
                <option value="">{commonValues.select}</option>
                {projectForm?.dropDowns?.type?.map((type) => (
                  <option key={type.id} value={type.label}>
                    {type.label}
                  </option>
                ))}
              </select>
              {errors.type && <p className="error mb-0">{projectForm.validations.type}</p>}
            </div>
            <div className="col-md- col-sm-12 mb-3">
              <label htmlFor="allocateClient">{projectForm.labels.allocateClient}</label>{' '}
              <span className="error">*</span>
              <select
                id="allocateClient"
                {...register('allocateClient', { required: true })}
                className="form-select"
              >
                <option value="">{commonValues.select}</option>
                {clients?.map((client) => (
                  <option key={client?.id} value={client?.id}>
                    {client?.name}
                  </option>
                ))}
              </select>
              {errors.allocateClient && (
                <p className="error mb-0">{projectForm.validations.allocateClient}</p>
              )}
            </div>

            <div className="d-flex justify-content-center pt-3">
              <button
                type="submit"
                className="btn submit-btn"
                disabled={isEdit && selectedClient?.status === 'Completed'}
              >
                {BUTTONS.submit}
              </button>
              <button type="button" className="btn cancel-btn ms-4" onClick={handleCancelEvent}>
                {BUTTONS.cancel}
              </button>
            </div>
          </div>
        </form>
        {/* eslint-enable react/jsx-props-no-spreading */}
      </Card>
    </div>
  );
}

export default ProjectForm;
