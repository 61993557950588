import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-hot-toast';
import DataTable from '../../common-components/data-table/DataTable';
import useProjectColumns from './ProjectUtilsHook';
import { commonValues } from '../../constants/AppConstants';
import { getAllProjects, deleteProject } from '../../services/projectService';
import ConfirmationModal from '../../common-components/confirmation-modal/ConfirmationModal';

function ProjectsTable() {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const handleDeleteClick = (data) => {
    setShowPopup(true);
    setSelectedData(data);
  };

  const columns = useProjectColumns(handleDeleteClick);

  const addProjectHandler = () => {
    navigate('/addProject');
  };

  const getProjects = () =>
    getAllProjects().then((response) => {
      setProjects(response || []);
    });

  useEffect(() => {
    getProjects();
  }, []);

  const onDeleteConfirm = (data) => {
    deleteProject(data?.id).then((res) => {
      if (res.status === commonValues.successful) {
        toast.success(res.message);
        getProjects();
      } else {
        toast.error(res.message);
      }
    });
  };

  const onConfirm = () => {
    onDeleteConfirm(selectedData);
    setShowPopup(false);
  };

  const onClosePopup = () => {
    setShowPopup(false);
    setSelectedData('');
  };

  return (
    <>
      <DataTable
        rows={projects}
        columns={columns}
        addButton
        height={500}
        tableTitle="Projects"
        pagination
        enableGlobalSearch
        addButtonListener={addProjectHandler}
      />
      <ConfirmationModal
        showPopup={showPopup}
        header="Delete"
        body="Are you sure you want to Delete the Project?"
        cancel="Cancel"
        confirm="Delete"
        onConfirm={onConfirm}
        onClosePopup={onClosePopup}
      />
    </>
  );
}

export default ProjectsTable;
