/* eslint-disable react-hooks/exhaustive-deps */
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import DataTable from '../../common-components/data-table/DataTable';
import useEmployeeColumns from './EmployeeUtilsHook';
import { commonValues, projectAllocationForm, notes } from '../../constants/AppConstants';
import useProjectAllocationColumns from './ProjectAllocationUtilsHook';
import { getAllEmployees, deleteEmployee } from '../../services/employeesService';
import ConfirmationModal from '../../common-components/confirmation-modal/ConfirmationModal';

function EmployeeTable() {
  const [employeeList, setEmployeeList] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [projectAllocationDetails, setProjectAllocationDetails] = useState([]);
  const navigate = useNavigate();
  const addEmployeeHandler = () => {
    navigate('/addEmployee');
  };

  const handleProjectAllocationData = (data) => {
    setProjectAllocationDetails(data);
  };

  const getEmployees = () =>
    getAllEmployees().then((res) => {
      setEmployeeList(res || []);
    });

  useEffect(() => {
    getEmployees();
  }, []);

  const handleDeleteClick = (data) => {
    setShowPopup(true);
    setSelectedData(data);
  };

  const onDeleteConfirm = (data) => {
    deleteEmployee(data?.id).then((res) => {
      if (res.status === commonValues.successful) {
        toast.success(res.message);
        getEmployees();
      } else {
        toast.error(res.message);
      }
    });
  };

  const onConfirm = () => {
    onDeleteConfirm(selectedData);
    setShowPopup(false);
  };

  const columns = useEmployeeColumns(handleDeleteClick, handleProjectAllocationData);
  const projectAllocationTableColumns = useProjectAllocationColumns();

  const onClosePopup = () => {
    setShowPopup(false);
    setSelectedData('');
  };

  return (
    <div className="pb-4">
      <DataTable
        addButton
        addButtonListener={addEmployeeHandler}
        height={500}
        columns={columns}
        rows={employeeList}
        tableTitle="Employees"
        pagination
        enableGlobalSearch
      />
      <DataTable
        height={250}
        columns={projectAllocationTableColumns}
        rows={projectAllocationDetails || []}
        tableTitle={projectAllocationForm.tableTitle}
        note={notes.allocationNote}
        pagination
      />
      <ConfirmationModal
        showPopup={showPopup}
        header="Delete"
        body="Are you sure you want to Delete the Employee?"
        cancel="Cancel"
        confirm="Delete"
        onConfirm={onConfirm}
        onClosePopup={onClosePopup}
      />
    </div>
  );
}

export default EmployeeTable;
