import { makeAPICall } from './api';
import { ApiPath, HttpMethods } from './api/Definitions';

export const getAllProjects = () => makeAPICall(ApiPath.getProject, HttpMethods.get, 'auth');

export const getProjectById = (id) =>
  makeAPICall(`${ApiPath.getProject}/${id}`, HttpMethods.get, 'auth');

export const createProject = (data) =>
  makeAPICall(ApiPath.getProject, HttpMethods.post, 'auth', data);

export const updateProject = (data, id) =>
  makeAPICall(`${ApiPath.getProject}/${id}`, HttpMethods.put, 'auth', data);

export const deleteProject = (id) =>
  makeAPICall(`${ApiPath.getProject}/${id}`, HttpMethods.delete, 'auth');
