import {
  dateRenderer,
  progressBarRenderer,
  yesOrNoStateRenderer,
  stateRenderer,
  yesOrNoRenderer,
} from '../../utils/Helper';
import TableHeaderBuilder from '../../utils/TableHeaderBuilder';

const useProjectAllocationColumns = () => {
  const columns = [
    new TableHeaderBuilder(
      null,
      null,
      'projectName',
      '',
      'Project Name',
      undefined,
      undefined,
      undefined
    ),
    new TableHeaderBuilder(
      dateRenderer,
      { textAlign: 'center' },
      'startDate',
      'my-header-class',
      'Start Date',
      140,
      150,
      undefined
    ),
    new TableHeaderBuilder(
      dateRenderer,
      { textAlign: 'center' },
      'endDate',
      'my-header-class',
      'End Date',
      130,
      150,
      undefined
    ),
    new TableHeaderBuilder(
      null,
      { textAlign: 'center' },
      'role',
      'my-header-class',
      'Role',
      250,
      160,
      undefined
    ),
    new TableHeaderBuilder(
      null,
      { textAlign: 'center' },
      'jobType',
      'my-header-class',
      'Type',
      120,
      100,
      undefined
    ),
    new TableHeaderBuilder(
      yesOrNoStateRenderer,
      { textAlign: 'center' },
      'primary',
      'my-header-class',
      'Primary',
      130,
      200,
      undefined
    ),
    new TableHeaderBuilder(
      stateRenderer,
      { textAlign: 'center' },
      'active',
      'my-header-class',
      'Status',
      100,
      200,
      undefined
    ),
    new TableHeaderBuilder(
      yesOrNoRenderer,
      { textAlign: 'center' },
      'billable',
      'my-header-class',
      'Is Billable',
      250,
      150,
      undefined
    ),
    new TableHeaderBuilder(
      progressBarRenderer,
      { textAlign: 'start' },
      'utilization',
      'my-header-class',
      'Utilization',
      undefined,
      undefined,
      undefined
    ),
  ];

  return columns;
};

export default useProjectAllocationColumns;
