import { useNavigate } from 'react-router';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { nameRenderer, dateRenderer, clientManagerRenderer } from '../../utils/Helper';
import TableHeaderBuilder from '../../utils/TableHeaderBuilder';

const useProjectColumns = (onDeleteClick) => {
  const navigate = useNavigate();
  const shouldShowIcons = false;

  const onEdit = (data) => {
    navigate(`/editProject/${data.id}`, { state: data });
  };

  const onDelete = (data) => {
    onDeleteClick(data); // Call the onDeleteClick callback
  };

  const actionsRenderer = (params) => (
    <>
      <FaEdit onClick={() => onEdit(params?.data)} className="edit-icon" />
      {shouldShowIcons && (
        <FaTrash onClick={() => onDelete(params?.data)} className="delete-icon ms-1" />
      )}
    </>
  );

  const columns = [
    new TableHeaderBuilder(
      null,
      { textAlign: 'start' },
      'name',
      '',
      'Name',
      undefined,
      undefined,
      undefined
    ),
    new TableHeaderBuilder(null, null, 'status', '', 'Status', undefined, undefined, undefined),
    new TableHeaderBuilder(
      nameRenderer,
      { textAlign: 'start' },
      'manager',
      '',
      'Project Manager',
      250,
      200,
      undefined
    ),
    new TableHeaderBuilder(null, { textAlign: 'start' }, 'type', '', 'Type', 140, 250, undefined),
    new TableHeaderBuilder(
      nameRenderer,
      { textAlign: 'start' },
      'client',
      '',
      'Client Name',
      250,
      170,
      undefined
    ),
    new TableHeaderBuilder(
      clientManagerRenderer,
      { textAlign: 'start' },
      'client',
      '',
      'Client Manager',
      250,
      180,
      undefined
    ),
    new TableHeaderBuilder(
      dateRenderer,
      { textAlign: 'center' },
      'startDate',
      'my-header-class',
      'Start Date',
      200,
      150,
      undefined
    ),
    new TableHeaderBuilder(
      dateRenderer,
      { textAlign: 'center' },
      'endDate',
      'my-header-class',
      'End Date',
      200,
      150,
      undefined
    ),
    new TableHeaderBuilder(
      actionsRenderer,
      { textAlign: 'center' },
      'actions',
      'my-header-class',
      'Actions',
      200,
      150,
      undefined
    ),
  ];

  return columns;
};

export default useProjectColumns;
